@import "../../../styles/colors.scss";
@import "../../../../public/assets/style/variables.scss";
@import "../.././../../public/assets/style/mixin.scss";

.stripe-button {
  margin-top: 20px;
  font-size: 20px;
  height: 45px;
  border-radius: 5px;
}
.page-activation {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: center;
  min-width: 500px;
  @media screen and (max-width: 690px) {
    min-width: 250px;
  }
}
.activated-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important ;
  width: 100% !important;
  height: 100% !important;
  .activated-p {
    margin: 6rem;
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.text,
.text-voucher {
  text-align: center;
  font-size: 20px;
  color: #000 !important;
  // color: $title_color !important;
  margin-top: 23px;
  max-width: 500px;
  opacity: 0.7;
}

.text-voucher {
  font-size: 13px;
}

.stripe-component-container {
  width: 100%;
  @include flexible(column, center, center);
}

.types-container {
  @include flexible("", center, center);
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  gap: 13px;
  margin-top: 2rem;
  padding-left: 1rem;

  .type {
    padding: 7px 26px;
    background: white;
    color: #333333;
    width: max-content;
    border-radius: 15px;
    border: 1px solid rgb(204, 200, 200);
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: 0.3s;

    &.active {
      color: #fff;
      // color: #333333;
      background: #660066 !important;

      // background: $active-color;
    }
  }
}

.activation-form-bottom {
  width: 500px;
}

.stripe-subscribe-button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background-color: white;
  border-color: white;
  color: #635bff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: #6b95b1;
    background-color: rgb(229, 226, 226);
    border-color: rgb(229, 226, 226);
  }
}

.powered-by-stripe {
  margin-top: 10px;
  text-align: center;
  color: #359cdd;
  font-style: italic;
  font-size: 10px;
}

.block {
  min-height: 36rem;
}

.back-btn-container {
  @include flexible("", "", flex-start);
}

.back-btn {
  @include flexible("", center, center);
  padding: 0.7rem 3rem;
  border-radius: $border-radius;

  span {
    color: $btn-text-color !important;
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.paypal-btn-container {
  transition: all 5ms ease-in-out;
  width: 100%;
}

.loading {
  min-height: 10vh;
  @include flexible("", center, center);
}

.error-text {
  display: block;
  color: #bf4342;
  font-size: 1.6rem !important;
}

.success-text {
  display: block;
  color: #8dec76;
  font-size: 1.6rem !important;
}

.open {
  height: 40px;
  transition: 0.2s;
}

.close {
  overflow: hidden;
  height: 0;
  transition: 0.2s;
}

.stripe-section {
  label {
    color: $text !important;
  }
}

.page-container {
  height: 100%;
  margin-top: 0;
  padding-top: 0;
  // justify-content: center;
  // width: 98%;
  // max-width: 700px;
  // align-items: center;
  // display: flex;
  // flex-direction: column;
  // padding: 15px 10px;
  // backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}

.packages-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 905px;
  margin-bottom: 20px;
  min-height: 122px;
}

.or:after {
  content: "";
  position: absolute;
  width: 43%;
  height: 0.5px;

  background: #ffffff27;
  transform: translate(4%, -50%);
  top: 50%;
}

.or:before {
  content: "";
  position: absolute;
  width: 43%;
  height: 0.5px;
  background: rgba(255, 255, 255, 0.152);

  transform: translate(-105%, -50%);
  top: 50%;
}

.or {
  margin-bottom: 15px;
  color: white;
  width: 100%;
  text-align: center;
  position: relative;
}

.cmi-payment {
  cursor: pointer;
  text-align: center;
  background: white;
  margin-bottom: 14px;
  border-radius: 5px;
  padding: 8px;
  color: red;
  font-weight: 700;
  font-size: 18px;
}

@media screen and (max-width: 400px) {
  .activation-form-bottom {
    padding: 0 30px !important;
  }

  .types-container {
    gap: 10px;
  }
}

@media screen and (max-width: 520px) {
  .activation-form-bottom {
    width: 350px;
  }
}

// .ant-form-item-explain-error {
//   font-style: italic !important;
//   float: right !important;
//   margin-top: 15px;
//   color: #f84e4e !important;
//   }
