.reseller-form {
    height: 100%;
    // min-height: calc(100vh - 80px);
    backdrop-filter: blur(2rem);
    padding: 3rem 3rem;
    border-radius: 5rem;
}

.checkbox-title,
.checkbox {
    color: #fff;
}

.reseller-registr-page__text{
    color: #fff;
}

.checkboxes {
    margin: 4.5rem 0;
}

.select {
    background: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    width: 38.7rem;
    padding-bottom: 1rem;
    margin-bottom: 4rem;
    color: white;
    font-size: 1.6rem;
    font-weight: 300;
    outline: none;
}

.select {
    option {
        background: #073e4a;
        font-size: 1.5rem;
    }
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 1rem;
    }
}

.reseller-page-agree-with-all{
    margin-bottom: 10px;
}

@media screen and (max-width: 500px) {
    .ant-tabs.ant-tabs-top.ant-tabs-centered.reseller-register-tabs{
      width: 320px !important;

    }
   
  }
  