.manage-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  background-color: #000 !important;

  .box-container {
    background-color: #efefef !important;
    border-radius: 2rem;
  }
}
