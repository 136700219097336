@import "../../../../public/assets/style/mixin.scss";

.page {
  @include flexible("", center, center);
  text-transform: uppercase;
  flex-direction: column !important;
  color: #fff !important;
  background: rgb(18, 16, 66);
  background: linear-gradient(
    18deg,
    rgba(18, 16, 66, 1) 0%,
    rgba(18, 16, 66, 1) 50%,
    rgba(169, 63, 92, 1) 100%,
    rgba(67, 79, 173, 0.5) 100%
  );
  // Title
  .manage-device-title,
  .login-container-title {
    font-size: 3rem;
    line-height: 3rem;
    font-weight: 700;
    margin: 1rem 0;
    opacity: 0.9;
    color: #fff;
    span {
    }
  }
  .title-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;

    .title-main-p {
      font-size: 1.2rem;
      line-height: 1.2rem;
      font-weight: 400;
      margin-top: 1rem;
      color: #9e9999 !important;
    }
    .setting-border-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;
      .border-left {
        width: 15rem;
        border: 1px solid #ddd;
      }
      .border-right {
        width: 15rem;
        border: 1px solid #ddd;
      }
      img {
        width: 1.5rem;
        display: inline;
        margin: 0 0.85rem;
      }
    }
  }
  // login container
  .login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    width: 100%;
    // background: #000;
    .login-container-title {
      color: #fff;
      margin-bottom: 4rem;
    }
    .login-btn {
      padding: 14px 26px;
      border-radius: 40px;
      display: block;
      font-weight: 600;
      margin-top: 7rem !important;
      outline: none;
      background-color: #660066;
      color: #fff;
      width: 23rem;
      text-transform: uppercase;
    }
    .input-container-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      input {
        padding: 0.75rem !important;
        width: 20rem;
        margin-right: 1rem;
        border-radius: 5rem;
        border: none !important;
        font-size: 1rem !important;
        outline: none;
      }
    }
  }
  .login-by-mac-page {
    margin: 100px 0 20px 0;
    padding: 95px 72px;
    background: rgba(103, 101, 113, 0.34);
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);
    @include flexible(column, center, center);
    border: 1px solid green;

    &__title {
      font-weight: 700;
      font-size: 48px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 100px;
    }

    &__form {
      width: 350px;
      @include flexible(column, center, "");
    }

    &__form__btn {
      text-align: center;
      margin-top: 70px;
      cursor: pointer;

      button {
        width: max-content;
        cursor: pointer;
      }
    }
  }
}

.description {
  color: #fff;
  margin: 16px;
  font-size: 16px;
}

@media screen and (max-width: 520px) {
  .login-by-mac-page {
    padding: 15vw 10vw !important;

    &__title {
      font-size: 36px !important;
    }

    &__form {
      width: 70vw !important;
    }

    &__form__btn {
      button {
        min-width: max-content !important;
      }
    }
  }
}

@media screen and (max-width: 896px) {
  .input-container-inner {
    flex-direction: column !important;
  }
  .referring-pages {
    // width: 370px !important;
    padding: 20px;
    margin: auto;
  }
}
