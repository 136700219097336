.page {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 0.5rem;
  align-items: center;
  min-width: 500px !important;
  min-height: 300px;
  @media screen and (max-width: 780px) {
    min-width: 250px !important;
  }
}

.playlist-page-container {
  color: #000 !important;
  padding: 0.75rem;

  .url {
    width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .title {
    font-weight: 700;
    font-size: 35px;
    color: #660066 !important;
    // color: #000 !important;
    margin-bottom: 30px;
    opacity: 0.6;
  }

  .single-playlist {
    padding: 15px 15px;
    background: #091522;
    opacity: 0.5;
    border-radius: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
    margin-bottom: 1rem;

    &__name-url {
      display: flex;
      align-items: center;
      gap: 59px;

      .name {
        font-weight: 600;
        font-size: 21px;
        color: #ffffff;
        // color: #000 !important;
      }

      .url {
        color: #8d8e99;
        font-weight: 400;
        font-size: 20px;
      }
    }

    .edit-delete {
      display: flex;
      align-items: center;
      gap: 18px;

      .edit,
      .delete {
        cursor: pointer;
      }
    }
  }

  .playlist-add-btn {
    padding: 13px;
    width: max-content;
    display: flex;
    align-items: center;
    gap: 36px;
    background: #2a2738;
    border-radius: 2rem;
    color: #ffffff;
    font-weight: 600;
    font-size: 1rem !important;
    margin-top: 1rem !important;
    position: relative;

    cursor: pointer;
    &:hover::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      border-bottom: 3px solid white;
      box-shadow: 0px -10px 20px 10px rgba(255, 255, 255, 0.5);
    }

    &:hover {
      border: 1px solid #7a7984 !important;
      box-shadow: inset 0 0 120px #5d5b6b;
    }
  }
}

.add-modal-form,
.edit-modal-form {
  // width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

@media screen and (max-width: 1700px) {
  .title {
    font-size: 33px !important;
    margin-bottom: 18px !important;
  }

  .single-playlist {
    &__name-url {
      .name {
        font-size: 17px !important;
      }

      .url {
        font-size: 17px !important;
      }
    }

    .playlist-add-btn {
      gap: 13px !important;
      font-size: 18px !important;
    }
  }

  .playlist-add-btn {
    // padding: 18px 21px !important;
    gap: 17px !important;
    font-size: 17px !important;
  }
}

.pin-modal-title {
  font-weight: 700;
  font-size: 23px;
  color: #ffffff;
  margin-bottom: 30px;
  text-align: center;
}

.pin-modal-form {
  width: 350px;
}

.protected-playlist-checkbox {
  color: white;
  margin-bottom: 18px;
}

.device-info {
  color: #000;
  font-size: 15px;
  margin-bottom: 40px;
  div {
    margin-bottom: 0.25rem;
  }
}

@media screen and (max-width: 1140px) {
  .playlist-page-container {
    padding: 40px;
  }

  .title {
    font-size: 30px !important;
  }
}

@media screen and (max-width: 960px) {
  .url {
    width: 50vw !important;
  }
}

@media screen and (max-width: 720px) {
  .single-playlist__name-url {
    gap: 20px !important;
  }

  .url {
    width: 30vw !important;
  }

  .playlist-page-container {
    padding: 5vw !important ;
  }
}

@media screen and (max-width: 350px) {
  .add-modal-form,
  .edit-modal-form {
    width: 85vw !important;
  }
}

@media screen and (max-width: 430px) {
  .pin-modal-form {
    width: 65vw;
  }
}
