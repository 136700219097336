@import "../../styles/colors.scss";
@import "public/assets/style/variables.scss";
@import "../../../public/assets/style/mixin.scss";

.activation-form-style {
  min-height: 250px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a {
    color: $active-color;
  }
}

.activate-device {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;

  section {
    align-items: center;
    display: flex;
    opacity: 0.8;
    width: 410px;
    border-radius: 15px;
    background: #000000cc;
    padding: 10px 40px;

    .text {
      text-align: end;
      margin: 0;
    }

    button {
      border-radius: 7px;
      margin-left: 20px;
      background-color: #d6d9da54;
      border-color: transparent;
      color: white;

      &:hover {
        background-color: #b80e0e;
        border-color: transparent;
        color: white;
      }
    }
  }
}

.packages-list {
  margin-top: 20px;
  // width: 640px;
  // display: grid;
  // // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  // grid-template-columns: 1fr 1fr 1fr;
  // align-items: center;
  // justify-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-height: 40vh;
}

.package {
  background-color: #fff;
  background-image: url(https://i.ibb.co/WVrMT5x/pricing-pattern.png) !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 5px;
  color: #333333;
  cursor: pointer;
  flex-direction: column;
  height: 260px;
  padding: 80px 8px 50px;
  text-align: center;
  transition: 0.2s;
  width: 170px;

  &:hover {
    background-image: url("../../images/package_hover_bg.png");
  }

  .price {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    font-size: 16px;
    gap: 3px;
    font-weight: bold;
    direction: rtl;
  }

  .name {
    color: #021678;
    font-size: 20px;
    font-weight: 800;
    line-height: 1.5;
    word-wrap: break-word;
  }

  .schedule-time {
    font-size: 20px;
  }

  .recomended {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 16px;
  }
}
.package.active {
  // margin-bottom: 40px;
  // height: 222px;
}
.activation-step2 {
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 50vh;

  .checkboxes {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .ant-checkbox-wrapper {
      margin: 0 !important;
      margin-top: 15px !important;
      font-size: 17px;
      a {
        color: white !important;

        &:hover {
          color: $blue !important;
        }
      }
    }
  }

  .warrning-text {
    width: 280px;
    text-align: center;
    color: #9d9191 !important;
    margin-bottom: 40px;
  }
}

.activation-step3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .email-mac-address {
    width: 300px;
    margin-top: 70px;
  }
}

.activation-step4 {
  width: 770px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 50vh;

  section {
    display: flex;
    width: 100%;
    margin-top: 40px;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    width: 50%;
    border-right: 2px solid white;
    padding-right: 20px;
    padding: 20px;
    gap: 15px;

    .button {
      width: 100% !important;
      padding: 10px;
      background-color: transparent;
      text-align: center;
      border-radius: 5px;
      font-size: 20px;
      font-weight: 600;
      color: $text;
      border: 2px solid $text;
      background-color: transparent;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        opacity: 0.9;
      }

      &.active {
        background-color: $text;
        color: $app;
      }
    }
  }

  .container {
    width: 59%;
    height: 100%;
    padding: 20px;
  }
}

.stripe-paypal-desibled-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-top: 20px;
    font-size: 20px;
    color: #fd7072 !important;
  }
}

.agree-with-all-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: $active-color;
  border-color: $active-color;
}
.coupon-activation-activate-btn,
.coupon-activation-activate-btn-url,
.coupon-activation-activate-btn-xtream,
.coupon-activation-activate-btn-mac {
  width: 40%;
  padding: 0.65rem 0 !important;
  align-self: center !important;
  text-align: center !important;
  margin: 0 auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 36px;
  background: #2a2738;
  border-radius: 2rem;
  color: #ffffff;
  font-weight: 600;
  font-size: 1rem !important;
  margin-top: 2.85rem !important;
  position: relative;
  cursor: pointer;
  &:hover::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    border-bottom: 3px solid white;
    box-shadow: 0px -10px 20px 10px rgba(255, 255, 255, 0.5);
  }

  &:hover {
    border: 1px solid #7a7984 !important;
    box-shadow: inset 0 0 120px #5d5b6b;
  }
}
// position each button
.coupon-activation-activate-btn {
  &:hover::after {
    bottom: 15.15rem;
    left: 49.5%;
  }
}
.coupon-activation-activate-btn-mac {
  &:hover::after {
    bottom: 0.25rem;
    left: 50%;
  }
}
.coupon-activation-activate-btn-url,
.coupon-activation-activate-btn-xtream {
  &:hover::after {
    bottom: 0.2rem;
    left: 49.5%;
  }
}

@media screen and (max-width: 772px) {
  .packages-list {
    // grid-template-columns: 1fr;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .package-list_title {
    font-size: 25px !important;
  }
}
