.page {
  max-width: 535px;
  padding: 1rem;
  .page-title {
    font-size: 1.35rem;
    line-height: 1.35rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  .warning-paragraph {
    margin: 1.5rem 0;
  }
  .submit-btn {
    width: 40%;
    padding: 0.65rem 0 !important;
    align-self: center !important;
    text-align: center !important;
    margin: 1rem auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 36px;
    background: #2a2738;
    border-radius: 2rem;
    color: #ffffff;
    font-weight: 600;
    font-size: 1rem !important;
    margin-top: 3.5rem !important;
    position: relative;
    cursor: pointer;
    &:hover::after {
      content: "";
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      border-bottom: 3px solid white;
      box-shadow: 0px -10px 20px 10px rgba(255, 255, 255, 0.5);
    }

    &:hover {
      border: 1px solid #7a7984 !important;
      box-shadow: inset 0 0 120px #5d5b6b;
    }
  }
  .msg-container {
    color: red;
    margin-top: 1rem;
  }
}
