@import "../../../../public//assets/style/variables.scss";
@import "../../../../public/assets/style/mixin.scss";

@import "../../../styles/colors.scss";
.main-h {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(18, 16, 66);
  background: linear-gradient(
    18deg,
    rgba(18, 16, 66, 1) 0%,
    rgba(18, 16, 66, 1) 50%,
    rgba(169, 63, 92, 1) 100%,
    rgba(67, 79, 173, 0.5) 100%
  );
  @media (max-width: 600px) {
    .main-hero {
      margin-top: 0 !important;
    }
  }

  .main-hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1000px !important;
    width: 65%;
    padding: 2rem;
    border-radius: 1rem;
    background: #fff;
    .carousel {
      border: 5px solid red !important;
    }

    .item-c {
      width: 100% !important;
      height: 100% !important;
      img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
      }
    }
    .content-holder {
      width: 100%;
      padding: 1.4rem;
      color: #000 !important;
      text-align: left;
      .hero-title {
        color: #1162cb;
        text-shadow: 0px 1px 0px #ccc;
        font-size: 1.5rem;
      }
      .hero-p {
        margin-top: 1rem;
        font-size: 0.9rem;
      }
      .feature {
        font-weight: 700;
      }
      .features-list {
        list-style: initial !important;
        padding: 0.5rem 2rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .main-hero {
    padding: 1rem;

    .img-holder {
      width: 100%;
      height: auto;
    }

    .carousel-holder {
      width: 100%;
      img {
        height: auto !important;
      }
    }

    .content-holder {
      padding: 1rem;
      .hero-title {
        font-size: 1.2rem;
      }
      .hero-p {
        font-size: 0.9rem;
      }
    }
  }
}

@media (max-width: 480px) {
  .main-hero {
    padding: 0.5rem;

    .img-holder {
      width: 100%;
    }

    .carousel-holder {
      img {
        height: auto !important;
      }
    }

    .content-holder {
      padding: 0.5rem;
      .hero-title {
        font-size: 1rem;
      }
      .hero-p {
        font-size: 0.8rem;
      }
    }
  }
}
