@import "public/assets/style/mixin.scss";
@import "public/assets/style/variables.scss";

@import "../../styles/colors.scss";

.btn {
  // padding: 14px 26px !important;
  // border-radius: 40px;
  // display: block;
  // font-weight: 600;
  // margin: 3rem auto !important;
  // outline: none !important;
  // background-color: #660066 !important;
  // color: #fff !important;
  // width: 23rem;
  // text-transform: uppercase;
  // border: none !important;
  // text-align: center;
  // height: initial !important;
  // &:hover {
  //   background-color: #74220a !important;
  // }
  width: 40%;
  padding: 20px 26px !important;
  align-self: center !important;
  text-align: center !important;
  margin: 0 auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 36px;
  background: #2a2738 !important;
  border: none;
  border-radius: 2rem;
  color: #ffffff;
  font-weight: 600;
  font-size: 1rem !important;
  margin-top: 2.85rem !important;
  outline: 0;
  position: relative;
  cursor: pointer;
  &:hover::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    border-bottom: 3px solid white;
    box-shadow: 0px -10px 20px 10px rgba(255, 255, 255, 0.5);
  }

  &:hover {
    border: 1px solid #7a7984 !important;
    box-shadow: inset 0 0 120px #5d5b6b;
    color: #fff !important;
  }
}

.large {
  width: 100%;
  height: 50px;
  // background-color: $active-color;
  color: white !important;

  &:hover {
    opacity: 0.8;
    color: white !important;
  }

  &:focus {
    // background-color: $active-color;
  }

  &:disabled {
    background-color: #b6b6b6a1;
    cursor: default;
    &:hover {
      background-color: #b6b6b6a1 !important;
    }
  }
}
