// src\pages\contact\style\contact.module.scss
@import "../../../../public/assets/style/variables.scss";
@import "../../../styles/colors.scss";

.contact-us_main {
  padding: 176px 0 166px;

  .form-image-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: #fff !important;
    border-radius: 2rem;
    margin-top: 2rem;
  }

  .contact-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    overflow: hidden;
  }

  .contact-us_wrapper {
    padding: 60px;
    // display: flex;
    justify-content: space-between;
    background-color: $secondary-dark;
    border-radius: 90px;
    backdrop-filter: blur(20px);

    .info-form_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    .info {
      width: 100%;
      .title {
        font-weight: 400;
        font-size: 48px;
        font-weight: 700;
        margin-bottom: 1.5rem;
        color: $title_color;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        text-transform: capitalize;

        span {
          color: $primary-color;
        }
      }
    }

    form {
      //   padding: 82px 50px;
      margin-top: 2rem;
      padding: 60px 30px;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border-radius: 5px;
      // width: 100%;
      max-width: 50%;

      .title-input,
      .email-input,
      .message-input {
        border: 1px solid rgba(7, 64, 76, 0.3);
        background-color: #fff;
        border-radius: 5px;
        padding: 15px 15px 15px 25px;
        font-size: 15px;
        color: rgba(7, 64, 76, 0.6) !important;
      }

      .message-input {
        resize: none;
      }
    }
  }
}

.contact-us_info {
  text-align: center !important;
  width: 100%;
  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    margin: 1rem 0;
    color: #ffffff;
  }

  .contact-list {
    list-style: none;
    display: flex !important;
    justify-content: center;
    align-items: center;
    border: 1px solid red;

    a {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      color: #fff !important;
      .icon {
        width: 90px;
        height: 90px;
        margin-right: 40px;
      }

      .list-info {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff !important;
        max-width: 220px;

        h3 {
          font-weight: 600;
          font-size: 20px;
          color: #fff !important;
        }

        p {
          font-weight: 400;
          font-size: 16px;
          color: #ffffff;
        }
      }
    }
  }

  ul {
    padding: 0 !important;
  }
}

@media screen and (max-width: 1300px) {
  .contact-us_main {
    .contact-us_wrapper {
      padding: 50px 40px;

      form {
        padding: 50px 40px;
        margin-top: 50px;
        width: 100%;

        .title-input,
        .email-input,
        .message-input {
          width: 100%;
        }
      }
    }
  }

  .contact-us_info {
    width: 100%;
    margin-top: 20px;
    .description {
      max-width: 90%;
    }
    .contact-list {
      a {
        .icon {
          width: 70px;
          height: 70px;
          margin-right: 30px;
        }

        .list-info {
          // max-width: 200px;
          h3 {
            font-size: 18px;
          }

          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}
.info-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #fff !important;
}
@media screen and (max-width: 992px) {
  .form-image-container {
    flex-direction: column;
    width: 60%;
  }
  .contact-us_main {
    padding: 130px 0 80px;
  }

  .info-form_wrapper {
    flex-direction: column;
    align-items: flex-start !important;
    text-align: left !important;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)) !important;
  }

  .contact-us_main {
    .contact-us_wrapper {
      flex-direction: column;

      .info {
        width: 80%;
        // order: 2;

        .title {
          font-size: 36px;
          position: absolute;
          top: 20px;
          left: 0;
          text-align: center;
          width: 100%;
        }
      }

      form {
        margin-bottom: 20px;
        // order: 1;
        display: flex;
        width: 100%;
        max-width: 75%;
      }
    }
  }

  .contact-us_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .contact-list {
      width: 95%;
      display: flex;
      justify-content: space-between;

      a {
        flex-direction: column;
        align-items: flex-start;
        max-width: 30%;

        .icon {
          margin-right: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 772px) {
  .contact-image-container {
    width: 90%;
    // margin-left: 2rem;
  }

  .contact-us_main {
    padding: 100px 0 80px;
    .contact-us_wrapper {
      padding: 20px 20px !important;

      form {
        width: 100% !important;
        max-width: 90% !important;
        margin: 2rem;
      }
    }
  }

  .contact-us_info {
    .description {
      width: 90%;
    }
    .contact-list {
      flex-direction: column;
      a {
        flex-direction: row;
        max-width: fit-content;
        .icon {
          width: 60px;
          height: 60px;
          // margin-right: 20px;
        }

        .list-info {
          // max-width: 200px;

          h3 {
            // font-size: 16px;
          }

          p {
            // font-size: 12px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .info-form_wrapper {
    align-items: flex-start;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
  }
  .contact-us_wrapper {
    padding: 20px 20px !important;

    form {
      width: 100% !important;
      max-width: 100% !important;
      margin: 2rem;
    }
  }

  .contact-us_info {
    .description {
      margin-top: 2rem;
    }
  }
}
