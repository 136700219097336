@import "../../../public/assets/style/variables.scss";
@import "../../../public/assets/style/mixin.scss";
@import "../../styles/colors.scss";

.footer {
  width: 100%;
  padding: 10px;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem 0;

  .terms-container {
    .footer-link {
      color: #fff !important;
      margin-right: 1rem;
      &:hover {
        color: red !important;
      }
    }
  }

  .reserved {
    color: white;

    a {
      color: white;
    }
  }

  .version {
    color: white;
  }
}

.footer-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.footer-navigation_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: #fff;
  // margin-bottom: 10px;

  a {
    font-size: 18px;
    font-weight: 300;
    color: white;
    white-space: nowrap;
  }

  a:not(:last-child) {
    margin-right: 40px;
  }
}

.copyright-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-links_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  gap: 10px;

  a {
    img {
      width: 30px;
      height: 30px;
      object-fit: contain;
    }
  }
}

.payments-cards {
  margin: 10px 0;
  width: 100%;
  text-align: center;

  img {
    width: 280px;
  }
}

@media screen and (max-width: 772px) {
  .footer-navigation_wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
      font-size: 14px;
      margin-bottom: 10px;
      margin-right: 0 !important;
    }
  }
  .copyright-wrapper {
    font-size: 0.75rem !important;
  }
}
