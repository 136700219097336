@import "../../../../public/assets/style/variables.scss";
@import "../../../styles/colors.scss";
@import "/public/assets/style/mixin.scss";

.payment-page-main {
  @include flexible("", center, center);
  min-height: calc(100vh - 80px);

  .warning-text {
    font-size: 15px;
    color: red;
    text-align: center;
  }

  .page-wrapper {
    margin: 100px 0 20px;
    padding: 2rem 3rem;
    @include flexible("", center, center);
    backdrop-filter: blur(55px);
    background-color: #00000070;
    border: 1px solid #0000001a;
    border-radius: 50px;
    backdrop-filter: blur(15px);

    .title {
      color: #ffffff;
      font-size: 20px;
      text-align: center;
    }

    .checked {
      position: absolute;
      right: 10px;
    }

    .package-style {
      margin: 25px 25px;
      background: #ffffff;
      border: 3px solid #ffffff;
      color: #222222;
      border-radius: 20px;
      padding: 15px 20px;
      height: 200px;
      width: 173px;
      position: relative;
    }

    .package-text-style-currency,
    .package-text-style {
      font-weight: 700;
      font-size: 23px;
      margin-bottom: 0;
      word-wrap: break-word;
      margin-right: 5px;
    }

    .package-text-style-currency {
      font-size: 16px;
    }

    .package-price {
      @include flexible("", "baseline", center);
      position: relative;
      bottom: -33px;
    }

    .payment-page-container {
      @include flexible(column, center, center);
    }

    .privacy-policy-text {
      color: #ffffff;
      margin: 30px 0 90px;
      font-size: 16px;
      text-align: center;
    }

    .mac {
      color: #ffffff;
      font-size: 1.6rem;
      display: block;
      margin: 28px 0 10px;
    }
  }
}

.payment {
  // @include flexible(column, center, center);
}

.mac-adress-input-contaner {
  @include flexible(column, center, center);
  width: 500px;

  &__text {
    color: #ffffff;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    margin-bottom: 100px;
  }

  .mac-input {
    width: 300px;
    margin-bottom: 70px;
  }
}

.or:after {
  content: "";
  position: absolute;
  width: 43%;
  height: 0.5px;

  background: rgba(255, 255, 255, 0.152);
  transform: translate(4%, -50%);
  top: 50%;
}

.or:before {
  content: "";
  position: absolute;
  width: 43%;
  height: 0.5px;
  background: rgba(255, 255, 255, 0.152);

  transform: translate(-105%, -50%);
  top: 50%;
}

.or {
  margin: 15px 0;
  color: white;
  width: 100%;
  text-align: center;
  position: relative;
}

@media screen and (max-width: 740px) {
  .page-wrapper {
    padding: 2rem !important;

    .title {
      font-size: 16px !important;
    }
  }

  .mac-input {
    width: 270px !important;
    font-size: 16px;

    input::placeholder {
      font-size: 14px;
    }
  }

  .mac-adress-input-contaner {
    width: inherit !important;
    font-size: 16px !important;

    &__text {
      font-size: 16px !important;
    }
  }

  .payment {
    // width: 300px !important;
  }
}
