@import "public/assets/style/variables.scss";

.header {
  width: 100%;
  // position: fixed;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  height: 65px;
  background-image: linear-gradient(45deg, #000000e3, transparent) !important;
}

.footer {
  background: linear-gradient(45deg, #010127, #212138, #01032a);
  // padding: 1rem 0;
}

.social-link {
  margin: 20px;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

.social-link img {
  width: 40px;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.social-link:hover img {
  transform: scale(1.35);
}

.social-links-container {
  position: fixed;
  z-index: 9;
  bottom: 40px;
  right: 20px;
}
