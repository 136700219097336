@import "../../styles/colors.scss";

.referring-pages {
  width: 800px !important;
  width: 100% !important;
  margin: auto;
  padding: 50px 2.5rem;
  background-color: #fff !important;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  &.privacy {
    min-height: 92vh;
    margin-top: -5rem;
    h3 {
      color: rgb(6, 109, 109) !important;
    }
    .read-more-btn {
      background-color: #006d6d;
      color: white;
      border: none;
      padding: 10px 20px;
      margin-top: 10px;
      cursor: pointer;
      font-size: 14px;
      border-radius: 5px;
      &:hover {
        background-color: #004c4c;
      }
    }
  }

  h1 {
    // color: #dfe8eb;
    color: #000 !important;
    font-size: 28px;
    margin-bottom: 1.5rem;
  }

  h3 {
    // color: #dfe8eb;
    color: #000 !important;

    font-size: 1rem;
    margin: 0.5rem 0 !important;
  }

  p {
    // color: #cad2d5 !important;
    color: #000 !important;
    font-size: 16px;
    max-width: 1300px;
  }

  ul,
  ol {
    margin: 30px 20px !important;
    // color: #cad2d5 !important;
    color: #000 !important;
  }

  .red-section {
    background-color: #ffb2b2;
    padding: 15px;
    margin: 30px 0px;
    border-radius: 2px;
    p {
      color: black !important;
      margin: 0;
    }
  }
}

.terms-of-use {
  div {
    margin: 1rem 0 !important;
  }
}

@media screen and (max-width: 896px) {
  .referring-pages {
    flex-direction: column;
    // width: 370px !important;
    padding: 20px;
    margin: auto;
  }
}
